<template>
<v-container>
  <!-- BOC:[breadcrumbs] -->
  <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
  <!-- EOC -->
  <!-- BOC:[form] -->
  <PageEdit
    :role="role"
    :model="model"
   :url="`${$api.servers.game}/api/v1/en/moderator/book/${greatGrandParent.id}/chapter/${grandParent.id}/question/${parent.id}/option/edit`"
    action="edit"
    :self="me"
    :callbackSuccess="callbackSuccess"
  ></PageEdit>
  <!-- EOC -->
</v-container>
</template>

<script>
  //BOC:[model]
  import modelGreatGrandParent from '@/models/items/book'
  import modelGrandParent from '@/models/items/chapter'
  import modelParent from '@/models/items/chapterQuestion'
  import model from '@/models/items/chapterQuestionOption'
  //EOC
  import PageEdit from "@/components/Moderator/QuestionOption/PageEdit";
  import { mapState } from 'vuex'
  export default {
    components:{
      PageEdit,
    },
    computed: mapState({
      school: state => state.school.data,
    }),
    props:[
      'greatGrandParent',
    'grandParent',
      'parent',
      'self',
    ],
    data: () => ({
      //BOC:[role]
      role:'Moderator',
      //EOC
      //BOC:[model]
      modelGreatGrandParent: modelGreatGrandParent,
      modelGrandParent: modelGrandParent,
      modelParent: modelParent,
      model: model,
      //EOC
      //BOC:[breadcrumbs]
      breadcrumbs: [],
      //EOC
      //BOC:[parent]
      myParent:{
        id:null,
      },
      //EOC
      //BOC:[self]
      me:{
        id:null,
      },
      //EOC
       //BOC:[grand parent]
       myGrandParent:{
        id:null,
      },
      //BOC:[grand parent]
      myGreatGrandParent:{
        id:null,
      },
      //EOC
    }),
    created() {
      //BOC:[guard]
      if(!this.model[this.role].edit) {
        this.$router.replace(this.$_getRouteRead(this.role,this.model.key,this.$route.params.id,this.self))
        return
      }
      //EOC
      //BOC:[parent]
      if(this.parent) this.myParent = this.$_.cloneDeep(this.parent)
      //EOC
      //BOC:[self]
      if(this.self) {
        this.me = this.$_.cloneDeep(this.self)
      }
      else {
        this.$router.replace(this.$_getRouteRead(this.role,this.model.key,this.$route.params.id))
      }
      //EOC
        //BOC:[grandparent]
        if(this.grandParent){
        this.myGrandParent = this.$_.cloneDeep(this.grandParent)
      } else{
        this.myGrandParent.id = this.$route.params.grandParentId
      }

      if(this.greatGrandParent){
        this.myGreatGrandParent = this.$_.cloneDeep(this.greatGrandParent)
      } else{
        this.myGreatGrandParent.id = this.$route.params.greatGrandParentId
      }
      //EOC
      //BOC:[breadcrumbs]
      this.breadcrumbs.push({
        text:this.$t("model.name.dashboard"),
        to:{name:'Page'+this.role+'Dashboard'},
        exact:true,
      })
      //
         //
         this.breadcrumbs.push({
        text:this.$t("model.name." + this.modelGreatGrandParent.name.plural.toLowerCase()),
        to:this.$_getRouteBrowse(this.role,this.modelGreatGrandParent.key),
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:(this.greatGrandParent && this.greatGrandParent.name) ? this.greatGrandParent.name : `${this.$t("model.name." + this.modelGreatGrandParent.name.singular.toLowerCase())} ${this.$route.params.greatGrandParentId}`,
        to:this.$_getRouteRead(this.role,this.modelGreatGrandParent.key,this.$route.params.greatGrandParentId,this.greatGrandParent),
        exact:true,
      })
      //
      let greatGrandParentPath = this.$_.cloneDeep(this.breadcrumbs[this.breadcrumbs.length-1].to)
      greatGrandParentPath.query = {tab:'Chapter'}
       //
       this.breadcrumbs.push({
        text:this.$t("model.name." + this.modelGrandParent.name.plural.toLowerCase()),
        to:greatGrandParentPath,
        exact:true,
      })
      this.breadcrumbs.push({
        text:(this.grandParent && this.grandParent.name) ? this.grandParent.name : `${this.$t("model.name." + this.modelGrandParent.name.singular.toLowerCase())} ${this.$route.params.grandParentId}`,
        to:this.$_getRouteChildRead(this.role,this.modelGrandParent.key,this.$route.params.grandParentId,this.grandParent,this.modelGreatGrandParent.key,this.$route.params.greatGrandParentId,this.greatGrandParent),
        exact:true,
      })
       //
       let path2 = this.$_.cloneDeep(this.breadcrumbs[this.breadcrumbs.length-1].to)
      path2.query = {tab:'ChapterQuestion'}
      this.breadcrumbs.push({
        text:this.$t("model.name." + this.modelParent.name.plural.toLowerCase()),
        to:path2,
        exact:true,
      })
      //
      //
      this.breadcrumbs.push({
        text:(this.myParent && this.myParent.name) ? this.myParent.name : `${this.$t("model.name." + this.modelParent.name.singular.toLowerCase())} ${this.$route.params.parentId}`,
        to:{ name: "PageModeratorBookChapterChapterQuestionRead",params:{grandParentId:this.$route.params.greatGrandParentId, parentId: this.$route.params.grandParentId,id:this.$route.params.parentId} },
        exact:true,
      })
      //
      let path = this.$_.cloneDeep(this.breadcrumbs[this.breadcrumbs.length-1].to)
      path.query = {tab:'QuestionOption'}
      this.breadcrumbs.push({
        text:this.$t("model.name." + this.model.name.plural.toLowerCase()),
        to:path,
        exact:true,
      })
      //
 //
 this.breadcrumbs.push({
        text:(this.me.name) ? this.me.name : `${this.$t("model.name." + this.model.name.singular.toLowerCase())} ${this.$route.params.id}`,
        to:{ name: "PageModeratorBookChapterChapterQuestionChapterQuestionOptionRead",},
        exact:true,
      })
      //EOC

       this.breadcrumbs.push({
        text:this.$t("action.edit"),
        to:this.$_getRouteGreatGrandChildEdit(this.role,this.model.key,this.$route.params.id,this.self,this.modelParent.key,this.$route.params.parentId,this.myParent,this.modelGrandParent.key,this.$route.params.grandParentId,this.myGrandParent,this.modelGreatGrandParent.key,this.$route.params.greatGrandParentId,this.myGreatGrandParent),
        exact:true,
      })
      //EOC
    },
    mounted() {
      //
    },
    methods: {
      callbackSuccess(resp) {
        let redirect = this.$_getRouteGreatGrandChildRead(this.role,this.model.key,this.$route.params.id,resp,this.modelParent.key,this.$route.params.parentId,this.myParent,this.modelGrandParent.key,this.$route.params.grandParentId,this.myGrandParent,this.modelGreatGrandParent.key,this.$route.params.greatGrandParentId,this.myGreatGrandParent)
        redirect.query = {tab:this.model.key}
        this.$router.push(redirect)
      },
    }
  }
</script>